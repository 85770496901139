<template>
	<div class="streak-modal-wrapper">
		<!-- Overlay sombre -->
		<div class="streak-modal-overlay" :class="{ active: isOverlayVisible }" @click="closeModalInParent"></div>

		<!-- Modal -->
		<div class="streak-modal-container" :class="animationClassName">
			<div class="close-button">
				<v-btn text icon @click="closeModalInParent">
					<v-icon x-large color="black">mdi-close</v-icon>
				</v-btn>
			</div>

			<v-container>
				<div class="streak-modal-content">
					<!-- En-tête avec le streak actuel -->
					<div class="modal-header">
						<img v-if="streakNumber > 0" src="@/assets/icons/emoji/gif-flame.gif" alt="flame" class="flame-icon" />

						<h2>
							<p class="subtitle">{{ streakNumber }} jour<span v-if="streakNumber > 1">s</span> d'affilée</p>
						</h2>
					</div>

					<!-- Activité récente -->
					<div class="recent-activity">
						<h3>Activité des 7 derniers jours</h3>
						<div class="activity-circles">
							<div v-for="(day, index) in orderedDays" :key="index" class="day-circle">
								<div class="circle" :class="{ active: day.active }">
									<v-icon v-if="day.active" color="white" size="20">mdi-check</v-icon>
									<v-icon v-else-if="isPastDay(day.date)" color="grey" size="18">mdi-close</v-icon>
								</div>
								<span class="day-label">{{ getDayLabel(day.date) }}</span>
							</div>
						</div>
					</div>

					<!-- Prochain objectif -->
					<div class="next-goal" v-if="streakNumber > 0">
						<h3>Prochain objectif</h3>
						<p v-if="hasExercisedToday">
							Excellent ! Tu as fait ta session aujourd'hui. Rendez-vous demain pour continuer ta série !
						</p>
						<p v-else>Tu as jusqu'à {{ nextDeadline }} pour garder ta série ! Il te reste {{ remainingTime }}.</p>
					</div>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { displayStreakNumber } from '@/utils/streak.js';

export default {
	name: 'StreakDetailsModal',
	props: {
		streak: {
			type: [Number, Object],
			required: true,
		},
		closeModal: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			animationClassName: '',
			timeOutObject: null,
			isOverlayVisible: false,
			recentDays: [],
			remainingTime: '',
			timerInterval: null,
			originalBodyStyles: null,
		};
	},
	computed: {
		...mapGetters('streak', {
			streakData: 'streak',
		}),
		streakNumber() {
			return displayStreakNumber(this.streakData);
		},
		hasExercisedToday() {
			if (!this.streakData || !this.streakData.last_update_streak_time) return false;
			return moment(this.streakData.last_update_streak_time).isSame(moment(), 'day');
		},
		nextDeadline() {
			return '23:59';
		},
		orderedDays() {
			// Réorganiser les jours pour commencer par lundi
			const days = [...this.recentDays];
			const mondayIndex = days.findIndex((day) => day.date.day() === 1); // 1 = Lundi

			if (mondayIndex !== -1) {
				// Réorganiser le tableau pour commencer par lundi
				const beforeMonday = days.slice(0, mondayIndex);
				const fromMonday = days.slice(mondayIndex);
				return [...fromMonday, ...beforeMonday];
			}

			return days;
		},
	},
	methods: {
		getDayLabel(date) {
			// Réorganiser les labels pour commencer par lundi
			const dayLabels = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
			const day = date.day();
			// Convertir le jour de la semaine (0-6, dimanche-samedi) en index (0-6, lundi-dimanche)
			const adjustedDay = day === 0 ? 6 : day - 1;
			return dayLabels[adjustedDay];
		},
		isPastDay(date) {
			const today = moment();
			const monday = moment().startOf('week').add(1, 'days'); // Lundi de cette semaine

			// Vérifier si la date est entre lundi et aujourd'hui (exclus)
			return moment(date).isSameOrAfter(monday, 'day') && moment(date).isBefore(today, 'day');
		},
		updateRemainingTime() {
			const now = moment();
			let endTime;

			if (this.hasExercisedToday) {
				// Si exercice fait aujourd'hui, deadline demain même heure
				endTime = moment().add(1, 'day').set({
					hour: now.hour(),
					minute: now.minute(),
					second: now.second(),
				});
			} else {
				// Sinon deadline à 23:59 aujourd'hui
				endTime = moment().endOf('day');
			}

			const duration = moment.duration(endTime.diff(now));
			const hours = Math.floor(duration.asHours());
			const minutes = Math.floor(duration.minutes());

			if (hours > 0) {
				this.remainingTime = `${hours}h ${minutes}min`;
			} else {
				this.remainingTime = `${minutes}min`;
			}
		},
		async initializeActivityData() {
			if (this.streakData) {
				const today = moment();
				const startStreak = moment(this.streakData.start_streak_time).startOf('day');
				const lastUpdate = moment(this.streakData.last_update_streak_time).startOf('day');
				const recentDays = [];

				// Créer le tableau des 7 derniers jours
				for (let i = 6; i >= 0; i--) {
					const date = moment(today).subtract(i, 'days');
					const isActive =
						this.streakNumber > 0 && date.isSameOrAfter(startStreak, 'day') && date.isSameOrBefore(lastUpdate, 'day');

					recentDays.push({
						date: date,
						active: isActive,
					});
				}

				this.recentDays = recentDays;
			} else {
				// Si pas de streak data, initialiser avec tous les jours inactifs
				const today = moment();
				const recentDays = [];

				for (let i = 6; i >= 0; i--) {
					recentDays.push({
						date: moment(today).subtract(i, 'days'),
						active: false,
					});
				}

				this.recentDays = recentDays;
			}
		},
		saveBodyStyles() {
			// Sauvegarder les styles originaux du body
			this.originalBodyStyles = {
				overflow: document.body.style.overflow,
				position: document.body.style.position,
				width: document.body.style.width,
			};
		},
		setModalBodyStyles() {
			// Appliquer les styles pour la modal
			document.body.style.overflow = 'hidden';
			document.body.style.position = 'fixed';
			document.body.style.width = '100%';
		},
		resetBodyStyles() {
			// Restaurer les styles originaux
			if (this.originalBodyStyles) {
				document.body.style.overflow = this.originalBodyStyles.overflow || '';
				document.body.style.position = this.originalBodyStyles.position || '';
				document.body.style.width = this.originalBodyStyles.width || '';
			} else {
				// Fallback au cas où originalBodyStyles n'est pas défini
				document.body.style.overflow = '';
				document.body.style.position = '';
				document.body.style.width = '';
			}
		},
		closeModalInParent() {
			this.animationClassName = this.decideAnimationClassName('OUT');
			this.isOverlayVisible = false;
			setTimeout(() => {
				this.resetBodyStyles();
				this.closeModal();
			}, 300);
		},
		decideAnimationClassName(direction) {
			if (direction === 'IN') {
				if (window.innerWidth >= 960) return 'slide_in';
				else return 'slide_up';
			} else if (direction === 'OUT') {
				if (window.innerWidth >= 960) return 'slide_out';
				else return 'slide_down';
			}
		},
		formatDate(date) {
			return moment(date).format('dddd DD/MM/YYYY');
		},
	},
	created() {
		this.timeOutObject = setTimeout(() => {
			this.animationClassName = this.decideAnimationClassName('IN');
			this.isOverlayVisible = true;
		}, 100);

		this.initializeActivityData();

		// Sauvegarder et configurer les styles du body
		this.saveBodyStyles();
		this.setModalBodyStyles();

		// Initialiser et démarrer le compteur
		this.updateRemainingTime();
		this.timerInterval = setInterval(this.updateRemainingTime, 60000);
	},
	beforeDestroy() {
		if (this.timeOutObject) clearTimeout(this.timeOutObject);
		if (this.timerInterval) clearInterval(this.timerInterval);
		this.resetBodyStyles();
	},
};
</script>

<style lang="scss" scoped>
.streak-modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.streak-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: opacity 0.3s ease;
	overflow: hidden;

	&.active {
		opacity: 1;
	}
}

.streak-modal-container {
	position: fixed;
	background: white;
	width: 100%;
	max-width: 500px;
	max-height: 90vh;
	border-radius: 8px;
	padding: 20px;
	overflow-y: auto;
	transition: all 0.3s ease;
	transform: translateX(100%);
	-webkit-overflow-scrolling: touch;

	/* Masquer la barre de défilement tout en gardant la fonctionnalité */
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	@media screen and (max-width: 959px) {
		bottom: 0;
		max-width: 100%;
		border-radius: 20px 20px 0 0;
		max-height: 80vh;
		transform: translateY(100%);
	}
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
}

.streak-modal-content {
	margin-top: 40px;
}

/* Animations */
.slide_in {
	transform: translateX(0) !important;
	opacity: 1;
}

.slide_out {
	transform: translateX(100%) !important;
	opacity: 0;
}

.slide_up {
	transform: translateY(0) !important;
	opacity: 1;
}

.slide_down {
	transform: translateY(100%) !important;
	opacity: 0;
}

/* Styles pour les statistiques */
.modal-header {
	text-align: center;
	margin-bottom: 30px;

	.streak-number {
		.flame-icon {
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
	}
}

.recent-activity {
	margin: 20px 0;

	h3 {
		font-size: 1.1em;
		margin-bottom: 15px;
		color: #333;
	}

	.activity-circles {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0;

		.day-circle {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;

			.circle {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background-color: #e0e0e0;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 8px;

				@media (max-width: 600px) {
					width: 40px;
					height: 40px;
				}

				@media (max-width: 360px) {
					width: 35px;
					height: 35px;
				}

				&.active {
					background-color: #15c39a;
				}

				.v-icon {
					&.mdi-close {
						opacity: 0.5; /* Rendre la croix plus subtile */
					}

					@media (max-width: 600px) {
						&.mdi-check {
							font-size: 18px !important;
						}
						&.mdi-close {
							font-size: 16px !important;
						}
					}
				}
			}

			.day-label {
				font-size: 1em;
				color: #666;
				text-transform: uppercase;

				@media (max-width: 600px) {
					font-size: 0.9em;
				}

				@media (max-width: 360px) {
					font-size: 0.8em;
				}
			}
		}
	}
}

.next-goal {
	margin: 20px 0;
	padding: 15px;
	background-color: #f5f5f5;
	border-radius: 8px;

	h3 {
		font-size: 1.1em;
		margin-bottom: 10px;
		color: #333;
	}

	p {
		color: #666;
		font-size: 0.9em;
		line-height: 1.4;
	}
}
</style>
