<template>
	<div class="to-leaderboard-btn-desktop" @click="toLeaderboardPage">
		<div class="cursor-pointer">
			<span class="ortho-bold fs12 ortho-text-gray cta" style="color: rgba(0, 0, 0, 0.54)">MON RANG</span>
			<v-icon class="mr3 arrow-right" color="#979FA9" small>mdi-arrow-right</v-icon>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DesktopButton',
	props: {
		topicSlug: String, // Topic of formation
	},
	methods: {
		toLeaderboardPage() {
			window.scrollTo(0, 0);
			if (['Orthographiq Leaderboard Weekly', 'Topic Leaderboard Weekly'].includes(this.$router.currentRoute.name))
				return;

			if (this.topicSlug === 'orthographe') this.$router.push({ name: 'Orthographiq Leaderboard Weekly' });
			else this.$router.push({ name: 'Topic Leaderboard Weekly' });

			//mixpanel tracker
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('dashboard-profile-goToLeaderboard');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.to-leaderboard-btn-desktop {
	display: flex;
	flex-direction: column;
	align-items: center;

	* {
		color: #b4b8b8;
	}

	&:hover {
		.cta {
			color: #686bdf !important;
		}
		.arrow-right {
			color: #686bdf !important;
			transform: translateX(30%);
		}
	}
}
</style>
