<template>
	<div class="pm-container hide-scroll-bar" :class="animationClassName">
		<v-container>
			<div class="header mt-3">
				<v-btn text icon class="close-btn" @click="closeModalInParent">
					<v-icon x-large color="black">mdi-close</v-icon>
				</v-btn>
			</div>

			<div class="pm-content">
				<div class="profile-section">
					<v-avatar color="white" class="mb10" size="110">
						<img v-if="profileData.gender == 'M'" src="@/assets/images/profilman.svg" />
						<img v-else-if="profileData.gender == 'F'" src="@/assets/images/profilwomen.svg" />
						<img v-else src="@/assets/images/profilNoIdentified.svg" />
					</v-avatar>
					<div class="user-info-container mb-5">
						<div class="name-container">
							<div class="text-wrapper">
								<h3 class="ortho-title">{{ profileData.first_name }} {{ profileData.last_name }}</h3>
							</div>
							<v-icon class="edit-icon" small @click="goToSettings">mdi-pencil</v-icon>
						</div>
						<div class="email-container">
							<div class="text-wrapper">
								<span class="ortho-email">{{ profileData.email }}</span>
							</div>
							<v-icon class="edit-icon" small @click="goToSettings">mdi-pencil</v-icon>
						</div>
					</div>
					<span>Membre depuis {{ dateDiff }} jours</span>
				</div>
				<div>
					<FormationEndDateProgressBar v-if="formationData.end_date" :formationData="formationData" />
					<ProfileStats v-if="hideOnRedaction === false" />
					<ProfileBadge />
					<ProfileSuccess />
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
import FormationEndDateProgressBar from '../FormationEndDateProgressBar';
import { setZindexByClassNames } from '@/utils/helper.js';
import ProfileBadge from './ProfileBadge';
import ProfileStats from './ProfileStats';
import ProfileSuccess from './ProfileSuccess';
import moment from 'moment';

export default {
	name: 'ProfileModal',
	components: { ProfileBadge, ProfileStats, ProfileSuccess, FormationEndDateProgressBar },
	data() {
		return {
			animationClassName: '',
			timeOutObject: null,
		};
	},
	props: {
		closeModal: Function,
		profileData: Object,
		formationData: Object,
	},
	computed: {
		dateDiff() {
			var eventdate = moment(this.profileData.creation_date);
			var todaysdate = moment();
			return todaysdate.diff(eventdate, 'days');
		},
		hideOnRedaction() {
			const redactionRouteNames = ['Topic Dashboard Chapter Detail', 'Topic Dashboard Parcours Detail'];
			if (redactionRouteNames.includes(this.$router.currentRoute.name)) return true;
			return false;
		},
	},
	methods: {
		closeModalInParent() {
			this.animationClassName = this.decideAnimationClassName('OUT');

			if (this.timeOutObject) clearTimeout(this.timeOutObject);
			this.timeOutObject = setTimeout(() => this.closeModal(), 800);
		},
		decideAnimationClassName(direction) {
			if (direction === 'IN') {
				if (window.innerWidth >= 960) return 'slide_in';
				// Desktop: Slide in
				else return 'slide_up'; // Mobile: Slide up
			} else if (direction === 'OUT') {
				if (window.innerWidth >= 960) return 'slide_out';
				// Desktop: Slide out
				else return 'slide_down'; // Mobile: Slide down
			}
		},
		goToSettings() {
			this.$router.push('/dashboard/parametres');
			this.closeModalInParent();
		},
	},
	created() {
		this.timeOutObject = setTimeout(() => (this.animationClassName = this.decideAnimationClassName('IN')), 100); // Set animation class
	},
	async mounted() {},
	beforeDestroy() {
		if (window.innerWidth < 960) setZindexByClassNames('navbar', 10);
		if (this.timeOutObject) clearTimeout(this.timeOutObject);
	},
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 20px;
	flex-direction: row-reverse;
}
.pm-container {
	overflow: scroll;
	height: 100vh;
	background-color: white;
	border-top-right-radius: 1.2rem;
	transition: all 0.8s;
	padding: 20px 20px 20px 20px;

	@media screen and (min-width: 960px) {
		transform: translateX(-100%);
		// max-width: 600px;
	}

	@media screen and (max-width: 959px) {
		border-top-left-radius: 1.2rem;
		transform: translateY(100%);
		height: 80vh;
		width: 100vw;
	}
	@media screen and (max-width: 375px) {
		height: 85vh;
	}
}

.pm-content {
	color: black;
	margin: 0 auto;
	@media screen and (min-width: 601px) and (max-width: 959px) {
		width: 80%;
	}
}

.slide_in {
	transform: translateX(0%);
}
.slide_out {
	transform: translateX(-100%);
}
.slide_up {
	transform: translateY(0);
}
.slide_down {
	transform: translateY(100%);
}

.profile-section {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.user-info-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;

	.name-container,
	.email-container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;

		.text-wrapper {
			text-align: center;
			position: relative;
			display: inline-block;
		}

		&:hover {
			.edit-icon {
				opacity: 1;
			}
		}

		.edit-icon {
			opacity: 0;
			position: absolute;
			left: calc(80% + 10px);
			transform: translateX(100%);
			color: lightgray;
			cursor: pointer;
			transition: opacity 0.2s ease;

			&:hover {
				color: darken(gray, 10%);
			}
		}
	}

	.ortho-title {
		margin-bottom: 4px;
	}
}

.ortho-email {
	color: grey;
}
</style>
