<template>
	<div class="badge-section">
		<div class="title-container-badge">
			<span class="section-title">Badges</span>
			<v-tooltip color="black">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-on="on" v-bind="attrs">
						<v-icon size="20px" color="grey lighten-1">mdi-information-outline</v-icon>
					</v-btn>
				</template>
				<span>Débloque des badges en fonction du nombre d'exercices faits</span>
			</v-tooltip>
		</div>
		<div class="badge-div">
			<div class="badge-container">
				<!-- 1 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="nbreUniqueExerciceDone >= 1" class="badge" v-bind="attrs" v-on="on">
								<img class="badge-img" src="@/assets/images/badges/badge1.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-badge-locked" src="@/assets/images/badges/badge1.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.badge1 }}</span>
					</v-tooltip>
				</div>

				<!-- 5 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="nbreUniqueExerciceDone >= 5" class="badge" v-bind="attrs" v-on="on">
								<img class="badge-img" src="@/assets/images/badges/badge5.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-badge-locked" src="@/assets/images/badges/badge5.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.badge5 }}</span>
					</v-tooltip>
				</div>

				<!-- 10 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="nbreUniqueExerciceDone >= 10" class="badge" v-bind="attrs" v-on="on">
								<img class="badge-img" src="@/assets/images/badges/badge10.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-badge-locked" src="@/assets/images/badges/badge10.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.badge10 }}</span>
					</v-tooltip>
				</div>

				<!-- 30 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="nbreUniqueExerciceDone >= 30" class="badge" v-bind="attrs" v-on="on">
								<img class="badge-img" src="@/assets/images/badges/badge30.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-badge-locked" src="@/assets/images/badges/badge30.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.badge30 }}</span>
					</v-tooltip>
				</div>

				<!-- 50 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="nbreUniqueExerciceDone >= 50" class="badge" v-bind="attrs" v-on="on">
								<img class="badge-img" src="@/assets/images/badges/badge50.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-badge-locked" src="@/assets/images/badges/badge50.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.badge50 }}</span>
					</v-tooltip>
				</div>

				<!-- 80 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="nbreUniqueExerciceDone >= 80" class="badge" v-bind="attrs" v-on="on">
								<img class="badge-img" src="@/assets/images/badges/badge80.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-badge-locked" src="@/assets/images/badges/badge80.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.badge80 }}</span>
					</v-tooltip>
				</div>

				<!-- 100 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="formationProgress.config.formular > 1">
								<div v-if="nbreUniqueExerciceDone >= 100" class="badge" v-bind="attrs" v-on="on">
									<img class="badge-img" src="@/assets/images/badges/badge100.png" />
								</div>
								<div v-else class="badge-grey" v-bind="attrs" v-on="on">
									<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
									<img class="img-badge-locked" src="@/assets/images/badges/badge100.png" />
								</div>
							</div>
						</template>
						<span>{{ badgeDescriptions.badge100 }}</span>
					</v-tooltip>
				</div>

				<!-- 160 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="formationProgress.config.formular >= 2">
								<div v-if="nbreUniqueExerciceDone >= 160" class="badge" v-bind="attrs" v-on="on">
									<img class="badge-img" src="@/assets/images/badges/badge160.png" />
								</div>
								<div v-else class="badge-grey" v-bind="attrs" v-on="on">
									<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
									<img class="img-badge-locked" src="@/assets/images/badges/badge160.png" />
								</div>
							</div>
						</template>
						<span>{{ badgeDescriptions.badge160 }}</span>
					</v-tooltip>
				</div>

				<!-- 200 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="formationProgress.config.formular > 2">
								<div v-if="nbreUniqueExerciceDone >= 200" class="badge" v-bind="attrs" v-on="on">
									<img class="badge-img" src="@/assets/images/badges/badge200.png" />
								</div>
								<div v-else class="badge-grey" v-bind="attrs" v-on="on">
									<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
									<img class="img-badge-locked" src="@/assets/images/badges/badge200.png" />
								</div>
							</div>
						</template>
						<span>{{ badgeDescriptions.badge200 }}</span>
					</v-tooltip>
				</div>

				<!-- 240 exercices fait -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="formationProgress.config.formular > 2">
								<div v-if="nbreUniqueExerciceDone >= 240" class="badge" v-bind="attrs" v-on="on">
									<img class="badge-img" src="@/assets/images/badges/badge240.png" />
								</div>
								<div v-else class="badge-grey" v-bind="attrs" v-on="on">
									<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
									<img class="img-badge-locked" src="@/assets/images/badges/badge240.png" />
								</div>
							</div>
						</template>
						<span>{{ badgeDescriptions.badge240 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 2 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 2" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak2.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak2.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak2 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 5 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 5" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak5.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak5.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak5 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 10 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 10" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak10.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak10.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak10 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 20 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 20" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak20.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak20.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak20 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 40 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 40" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak40.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak40.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak40 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 50 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 50" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak50.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak50.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak50 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 70 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 70" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak70.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak70.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak70 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 100 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 100" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak100.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak100.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak100 }}</span>
					</v-tooltip>
				</div>

				<!-- Streak > 200 jours -->
				<div>
					<v-tooltip bottom content-class="badge-tooltip" :disabled="$vuetify.breakpoint.mobile">
						<template v-slot:activator="{ on, attrs }">
							<div v-if="streak.highest_streak >= 200" class="badge streak" v-bind="attrs" v-on="on">
								<img class="badge-img streak" src="@/assets/images/badges/streak200.png" />
							</div>
							<div v-else class="badge-grey" v-bind="attrs" v-on="on">
								<v-icon class="icon-badge-locked" size="40" color="white">mdi-lock-outline</v-icon>
								<img class="img-streak-locked" src="@/assets/images/badges/streak200.png" />
							</div>
						</template>
						<span>{{ badgeDescriptions.streak200 }}</span>
					</v-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ProfileBadge',
	data() {
		return {
			badgeDescriptions: {
				// Exercise badges
				badge1: 'Premier pas : Complétez votre premier exercice',
				badge5: 'En route : Complétez 5 exercices uniques',
				badge10: 'Apprenti : Complétez 10 exercices uniques',
				badge30: 'Intermédiaire : Complétez 30 exercices uniques',
				badge50: 'Avancé : Complétez 50 exercices uniques',
				badge80: 'Expert : Complétez 80 exercices uniques',
				badge100: 'Maître : Complétez 100 exercices uniques',
				badge160: 'Grand Maître : Complétez 160 exercices uniques',
				badge200: 'Légende : Complétez 200 exercices uniques',
				badge240: 'Immortel : Complétez 240 exercices uniques',
				// Streak badges
				streak2: 'Régulier : Maintenez une série de 2 jours consécutifs',
				streak5: 'Constant : Maintenez une série de 5 jours consécutifs',
				streak10: 'Déterminé : Maintenez une série de 10 jours consécutifs',
				streak20: 'Persévérant : Maintenez une série de 20 jours consécutifs',
				streak40: 'Infatigable : Maintenez une série de 40 jours consécutifs',
				streak50: 'Tenace : Maintenez une série de 50 jours consécutifs',
				streak70: 'Inébranlable : Maintenez une série de 70 jours consécutifs',
				streak100: 'Légendaire : Maintenez une série de 100 jours consécutifs',
				streak200: 'Immortel : Maintenez une série de 200 jours consécutifs',
			},
		};
	},
	computed: {
		...mapGetters('profile', {
			exercicesInfos: 'exercicesInfos',
			formationProgress: 'formationProgress',
		}),
		...mapGetters('streak', { streak: 'streak' }),
		nbreUniqueExerciceDone() {
			return this.exercicesInfos.length;
		},
	},
	async created() {
		if (this.streak == null && this.profileInfos._id) {
			this.$store.dispatch('streak/searchStreakByUserId', { userId: this.profileInfos._id });
		}
	},
};
</script>

<style>
/* Style global pour les tooltips des badges */
.v-tooltip__content.badge-tooltip {
	z-index: 99999 !important;
	background-color: rgba(0, 0, 0, 0.8) !important;
	color: white !important;
	border-radius: 4px !important;
	padding: 8px 12px !important;
	font-size: 14px !important;
}
</style>

<style lang="scss" scoped>
.badge-section {
	background: #ffffff;
	border-radius: 8px;

	.title-container-badge {
		display: flex;
		align-items: center;
		margin-bottom: 24px;

		.section-title {
			font-size: 1.25rem;
			font-weight: 600;
			color: #2c3e50;
			margin-right: 8px;
		}
	}

	.badge-div {
		width: 100%;

		.badge-container {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
			gap: 20px;

			// Common badge styles
			.badge,
			.badge-grey {
				position: relative;
				border-radius: 8px;
				padding: 3px;
				transition: all 0.3s ease;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					transform: translateY(-5px);
				}

				// Badge image styles
				.badge-img {
					width: 100%;
					height: auto;
					transition: all 0.3s ease;
				}
			}

			// Unlocked badge styles
			.badge {
				background: linear-gradient(145deg, #ffffff, #f5f5f5);
				box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

				&:hover {
					box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);

					.badge-img {
						filter: brightness(1.1);
					}
				}

				// Special streak badge styling
				&.streak {
					position: relative;
					&::after {
						content: '🔥';
						position: absolute;
						top: -8px;
						right: -8px;
						font-size: 16px;
					}
				}
			}

			// Locked badge styles
			.badge-grey {
				background: rgba(245, 245, 245, 0.7);
				backdrop-filter: blur(5px);
				position: relative;
				overflow: hidden;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba(128, 128, 128, 0.1);
					backdrop-filter: blur(2px);
				}

				.icon-badge-locked {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 2;
					opacity: 0.7;
				}

				.img-badge-locked,
				.img-streak-locked {
					opacity: 0.3;
					filter: grayscale(100%);
					width: 100%;
					height: auto;
				}
			}

			// Category separator
			.category-separator {
				grid-column: 1 / -1;
				height: 1px;
				background: linear-gradient(to right, transparent, #e0e0e0, transparent);
				margin: 16px 0;
			}
		}
	}
}

// Animation keyframes for new badges
@keyframes glow {
	0% {
		box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
	}
	50% {
		box-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
	}
	100% {
		box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
	}
}

.new-badge {
	animation: glow 2s infinite;
}
</style>
