<template>
	<div class="pm-container" :class="animationClassName">
		<ParcoursModalContentSkeleton v-if="loading" :closeModal="closeModalInParent" />
		<v-container v-else>
			<div class="header mt-3">
				<v-btn text icon @click="closeModalInParent">
					<v-icon x-large color="black">mdi-close</v-icon>
				</v-btn>
				<ParcoursStatus
					v-if="allParcours.length !== 0 && allFormationProgress"
					:atParcoursId="allParcours[pagination.at - 1]._id"
					:formationProgressArr="allFormationProgress"
				/>
			</div>

			<div v-if="allParcours.length !== 0" class="pm-content">
				<TopicName :parcours="allParcours[pagination.at - 1]" :topics="topics" />
				<Content :parcours="allParcours[pagination.at - 1]" :formationProgressArr="allFormationProgress" />
			</div>
			<div class="actions">
				<NavAndActions :pagination="pagination" :next="next" :previous="previous" />
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import NavAndActions from './NavAndActions';
import TopicName from './TopicName.vue';
import Content from './Content';
import ParcoursStatus from './ParcoursStatus.vue';
import { setZindexByClassNames } from '@/utils/helper.js';
import { ParcoursItemStatus } from '@/constants/parcours.js';
import ParcoursModalContentSkeleton from '@/components/skeletons/ParcoursModalContentSkeleton.vue';

export default {
	name: 'ParcoursModal',
	components: {
		NavAndActions,
		TopicName,
		Content,
		ParcoursStatus,
		ParcoursModalContentSkeleton,
	},
	data() {
		return {
			loading: true,
			allParcours: [], // All parcours of a topic
			pagination: { at: 1, total: 1 },
			animationClassName: '',
			timeOutObject: null,
			allFormationProgress: null,
		};
	},
	props: { closeModal: Function },
	computed: {
		...mapGetters('resources', { topics: 'topics' }),
		...mapGetters('profile', {
			allFormationsAndConfigs: 'allFormationsAndConfigs',
			formationProgress: 'formationProgress',
		}),
	},
	created() {
		this.timeOutObject = setTimeout(() => (this.animationClassName = this.decideAnimationClassName('IN')), 100); // Set animation class
	},
	async mounted() {
		if (window.innerWidth < 960) setZindexByClassNames('navbar', 0);

		const currentFormationProgressId = this.formationProgress.formation._id; // What is current formation progress id
		const currentTopicId = this.formationProgress.config.topic_id._id; // What is current topic id
		const formations = this.allFormationsAndConfigs; // All formations of users

		const allTopics = await this.$store.dispatch('resources/getAllTopicsAndParcours'); // Not found topic -> fetch data
		const parcours = allTopics.reduce((accum, topic) => {
			if (topic._id !== currentTopicId) return accum;
			return [
				...accum,
				...topic.parcours.map((parcours) => {
					parcours.topicSlug = topic.slug;
					return parcours;
				}),
			];
		}, []);

		const notYetGetFormationId = formations.filter(
			(f) => f._id !== currentFormationProgressId && f.config.topic_id._id === currentTopicId
		); // Find missing formation id and formation must be same topic as current formation

		if (notYetGetFormationId.length === 0) {
			this.allFormationProgress = [this.formationProgress];
		} else {
			// Get the rest of formation progress
			const formationIds = notYetGetFormationId.map((formation) => formation._id);
			const othersFormationProgress = await this.fetchFormationProgressByIds(formationIds);
			this.allFormationProgress = [this.formationProgress, ...othersFormationProgress];
		}

		this.allParcours = this.arrangeParcoursOrder(parcours, this.allFormationProgress);
		this.pagination = { at: 1, total: this.allParcours.length }; // Set pagination
		await this.delay();
		this.loading = false;
	},
	methods: {
		closeModalInParent() {
			this.animationClassName = this.decideAnimationClassName('OUT');

			if (this.timeOutObject) clearTimeout(this.timeOutObject);
			this.timeOutObject = setTimeout(() => this.closeModal(), 800);
		},
		next() {
			if (this.pagination.at === this.pagination.total) return;
			this.pagination.at = this.pagination.at + 1;
		},
		previous() {
			if (this.pagination.at === 1) return;
			this.pagination.at = this.pagination.at - 1;
		},
		decideAnimationClassName(direction) {
			if (direction === 'IN') {
				if (window.innerWidth >= 960) return 'slide_in';
				// Desktop: Slide in
				else return 'slide_up'; // Mobile: Slide up
			} else if (direction === 'OUT') {
				if (window.innerWidth >= 960) return 'slide_out';
				// Desktop: Slide out
				else return 'slide_down'; // Mobile: Slide down
			}
		},
		arrangeParcoursOrder(allParcours, formationProgressArr) {
			const doingOrthograph = [];
			const completedOrthograph = [];
			const lockedOrthograph = [];
			const notOwnedOrthograph = [];

			const doingRedaction = [];
			const completedRedaction = [];
			const lockedRedaction = [];
			const notOwnedRedaction = [];
			allParcours.forEach((parcours) => {
				const formationProgressHaveThisParcoursId = formationProgressArr.find((progress) => {
					return progress.config.parcours_list.find((parcoursItem) => {
						return parcoursItem.parcours_id._id === parcours._id;
					});
				});
				if (formationProgressHaveThisParcoursId == null) {
					// Dont owned this parcours
					if (parcours.topicSlug === 'orthographe') notOwnedOrthograph.push(parcours);
					else notOwnedRedaction.push(parcours);
				} else {
					// Owned this parcours
					const statusOfThisParcoursItem = formationProgressHaveThisParcoursId.config.parcours_list.find(
						(parcoursItem) => {
							return parcoursItem.parcours_id._id === parcours._id;
						}
					).status;

					if (parcours.topicSlug === 'orthographe') {
						if (
							[ParcoursItemStatus.open, ParcoursItemStatus.started, ParcoursItemStatus.ongoing].includes(
								statusOfThisParcoursItem
							)
						) {
							doingOrthograph.push(parcours);
						} else if (ParcoursItemStatus.completed === statusOfThisParcoursItem) {
							completedOrthograph.push(parcours);
						} else {
							lockedOrthograph.push(parcours);
						}
					} else {
						if (
							[ParcoursItemStatus.open, ParcoursItemStatus.started, ParcoursItemStatus.ongoing].includes(
								statusOfThisParcoursItem
							)
						) {
							doingRedaction.push(parcours);
						} else if (ParcoursItemStatus.completed === statusOfThisParcoursItem) {
							completedRedaction.push(parcours);
						} else {
							lockedRedaction.push(parcours);
						}
					}
				}
			});
			return [
				...doingOrthograph,
				...doingRedaction,
				...completedOrthograph,
				...completedRedaction,
				...lockedOrthograph,
				...lockedRedaction,
				...notOwnedOrthograph,
				...notOwnedRedaction,
			]; // Owned orthograph -> Owned redaction -> not Owned Orthograph -> not Owned redaction
		},
		async fetchFormationProgressByIds(formationIds) {
			const formationProgressArr = [];
			await Promise.all(
				formationIds.map(async (id) => {
					const formationProgress = await this.$store.dispatch('profile/formationProgress', {
						idFormation: id,
						noUpdateStore: true,
					});
					formationProgressArr.push(formationProgress);
				})
			);

			return formationProgressArr;
		},
		delay() {
			return new Promise((resolve) => setTimeout(resolve, 1000));
		},
	},
	beforeDestroy() {
		if (window.innerWidth < 960) setZindexByClassNames('navbar', 10);
		if (this.timeOutObject) clearTimeout(this.timeOutObject);
	},
};
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	height: 46px;
	@media screen and (min-width: 960px) {
		margin-bottom: 3rem;
	}
	@media screen and (max-width: 959px) {
		flex-direction: row-reverse;
	}
}
.pm-container {
	height: 100vh;
	background-color: white;
	border-top-left-radius: 1.2rem;
	transition: all 0.8s;

	@media screen and (min-width: 960px) {
		transform: translateX(100%);
		max-width: 600px;
	}

	@media screen and (max-width: 959px) {
		border-top-right-radius: 1.2rem;
		transform: translateY(100%);
		height: 80vh;
		width: 100vw;
	}
	@media screen and (max-width: 375px) {
		height: 85vh;
	}
}

.pm-content {
	margin: 0 auto;
	@media screen and (min-width: 601px) and (max-width: 959px) {
		width: 80%;
	}
}

.actions {
	margin-top: 2.5rem;
	@media screen and (max-width: 959px) {
		margin-top: 1rem;
	}
}

.slide_in {
	transform: translateX(0);
}
.slide_out {
	transform: translateX(100%);
}
.slide_up {
	transform: translateY(0);
}
.slide_down {
	transform: translateY(100%);
}
</style>

parcours 5f1ec88cce948a001716e63b cat 5f1ec8d1ce948a001716e63c ex 5f1ec91cce948a001716e63d
