var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"badge-section"},[_c('div',{staticClass:"title-container-badge"},[_c('span',{staticClass:"section-title"},[_vm._v("Badges")]),_c('v-tooltip',{attrs:{"color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20px","color":"grey lighten-1"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('span',[_vm._v("Débloque des badges en fonction du nombre d'exercices faits")])])],1),_c('div',{staticClass:"badge-div"},[_c('div',{staticClass:"badge-container"},[_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.nbreUniqueExerciceDone >= 1)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge1.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge1.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge1))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.nbreUniqueExerciceDone >= 5)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge5.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge5.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge5))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.nbreUniqueExerciceDone >= 10)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge10.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge10.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge10))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.nbreUniqueExerciceDone >= 30)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge30.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge30.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge30))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.nbreUniqueExerciceDone >= 50)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge50.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge50.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge50))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.nbreUniqueExerciceDone >= 80)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge80.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge80.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge80))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.formationProgress.config.formular > 1)?_c('div',[(_vm.nbreUniqueExerciceDone >= 100)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge100.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge100.png")}})],1)]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge100))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.formationProgress.config.formular >= 2)?_c('div',[(_vm.nbreUniqueExerciceDone >= 160)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge160.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge160.png")}})],1)]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge160))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.formationProgress.config.formular > 2)?_c('div',[(_vm.nbreUniqueExerciceDone >= 200)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge200.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge200.png")}})],1)]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge200))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.formationProgress.config.formular > 2)?_c('div',[(_vm.nbreUniqueExerciceDone >= 240)?_c('div',_vm._g(_vm._b({staticClass:"badge"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img",attrs:{"src":require("@/assets/images/badges/badge240.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-badge-locked",attrs:{"src":require("@/assets/images/badges/badge240.png")}})],1)]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.badge240))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 2)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak2.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak2.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak2))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 5)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak5.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak5.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak5))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 10)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak10.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak10.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak10))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 20)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak20.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak20.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak20))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 40)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak40.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak40.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak40))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 50)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak50.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak50.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak50))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 70)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak70.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak70.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak70))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 100)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak100.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak100.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak100))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"badge-tooltip","disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.streak.highest_streak >= 200)?_c('div',_vm._g(_vm._b({staticClass:"badge streak"},'div',attrs,false),on),[_c('img',{staticClass:"badge-img streak",attrs:{"src":require("@/assets/images/badges/streak200.png")}})]):_c('div',_vm._g(_vm._b({staticClass:"badge-grey"},'div',attrs,false),on),[_c('v-icon',{staticClass:"icon-badge-locked",attrs:{"size":"40","color":"white"}},[_vm._v("mdi-lock-outline")]),_c('img',{staticClass:"img-streak-locked",attrs:{"src":require("@/assets/images/badges/streak200.png")}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.badgeDescriptions.streak200))])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }